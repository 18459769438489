import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArticle, LazyBlock, LazyBreadcrumbs, LazyButton, LazyDumb, LazyElement, LazyFooter, LazyHubChildren, LazyImageBanner, LazyImageLogo, LazyImagePicture, LazyImageReview, LazyJackpots, LazyMenu, LazyPassport, LazyPromoCategories, LazyRating, LazyRawText, LazyRegion, LazyScrollToTop, LazySeeMore, LazyShowImage, LazySmartLink, LazyStructurizerTree, LazyTaxonomy, LazyTime, LazyUziQuery, LazyViewCard, LazyViewCsv, LazyViewList, LazyViewTable } from '#components'
const lazyGlobalComponents = [
  ["Article", LazyArticle],
["Block", LazyBlock],
["Breadcrumbs", LazyBreadcrumbs],
["Button", LazyButton],
["Dumb", LazyDumb],
["Element", LazyElement],
["Footer", LazyFooter],
["HubChildren", LazyHubChildren],
["ImageBanner", LazyImageBanner],
["ImageLogo", LazyImageLogo],
["ImagePicture", LazyImagePicture],
["ImageReview", LazyImageReview],
["Jackpots", LazyJackpots],
["Menu", LazyMenu],
["Passport", LazyPassport],
["PromoCategories", LazyPromoCategories],
["Rating", LazyRating],
["RawText", LazyRawText],
["Region", LazyRegion],
["ScrollToTop", LazyScrollToTop],
["SeeMore", LazySeeMore],
["ShowImage", LazyShowImage],
["SmartLink", LazySmartLink],
["StructurizerTree", LazyStructurizerTree],
["Taxonomy", LazyTaxonomy],
["Time", LazyTime],
["UziQuery", LazyUziQuery],
["ViewCard", LazyViewCard],
["ViewCsv", LazyViewCsv],
["ViewList", LazyViewList],
["ViewTable", LazyViewTable],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
